<template>
  <div class="mainBox">
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type=='edit'?$t('cip.plat.sys.user.title.editHeadTitle'):$t('cip.plat.sys.user.title.addHeadTitle')"
                 @head-save="headSave('save')" @head-cancel="headCancel" @head-save-back="headSave('back')">
    </head-layout>
    <div class="tabsBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用户信息" name="user">
          <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-collapse v-model="activeNames">
              <el-collapse-item title="基础信息" name="1">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="用户编号:" prop="code">
                      <el-input v-model="dataForm.code" placeholder="请输入用户编号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户姓名:" prop="realName.zh">
                      <el-input v-model="dataForm.realName.zh" placeholder="请输入用户姓名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="登录账号:" prop="account">
                      <el-input v-model="dataForm.account" placeholder="请输入登录账号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="用户性别:" prop="sex">
                      <el-select v-model="dataForm.sex" placeholder="请选择用户性别">
                        <el-option
                          v-for="item in sexOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="Number(item.dictKey)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="手机号码:" prop="phone">
                      <el-input v-model="dataForm.phone" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="是否启用:" prop="isEnabled">
                      <el-select v-model="dataForm.isEnabled" placeholder="请选择是否启用">
                        <el-option
                          v-for="item in isEnabledOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="Number(item.dictKey)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="档案信息" name="2">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="所在地:" prop="personnelLocation">
                      <el-radio-group v-model="dataForm.personnelLocation">
                        <el-radio :label="Number(item.dictKey)" v-for="(item,index) in locationOptions"
                                  :key="item.dictKey">{{ item.dictValue }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="国籍:" prop="nationality">
                      <el-select v-model="dataForm.nationality" filterable placeholder="请选择国籍">
                        <el-option
                          v-for="item in countryOptions"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="人员类型:" prop="personnelType">
                      <el-select v-model="dataForm.personnelType" placeholder="请选择人员类型">
                        <el-option
                          v-for="item in personnelTypeOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="Number(item.dictKey)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item title="所属部门" name="3">
                <el-row>
                  <el-col :span="24">
                    <el-form-item prop="dept" label-width="0">
                      <el-tag v-for="(item,index) in dataForm.roleIds" :key="item.id" style="margin-right: 12px">
                        {{ item.deptName }}
                      </el-tag>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="组织部门" name="dept">
          <head-layout :head-btn-options="deptHeadOptions"
                       @head-add="addDept">
          </head-layout>
          <el-table
            :data="dataForm.roleIds"
            border
            stripe
            size="medium"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index"
              align="center"
              fixed="left"
              width="50">
            </el-table-column>
            <el-table-column
              prop="deptName"
              label="组织名称"
              fixed="left"
              width="200">
            </el-table-column>
            <el-table-column
              prop="isDefaultDept"
              label="是否主组织"
              align="center"
              fixed="left"
              width="150">
              <template slot-scope="scope">
                <el-switch
                  @change="chooseDefault(scope)"
                  v-model="scope.row.isDefaultDept"
                  active-text="是"
                  inactive-text="否"
                  active-value="1"
                  inactive-value="0">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="isDisplay"
              label="是否默认部门"
              align="center"
              fixed="left"
              width="150">
              <template slot-scope="scope">
                <el-switch
                  @change="chooseDisplay(scope)"
                  v-model="scope.row.isDisplay"
                  active-text="是"
                  inactive-text="否"
                  active-value="1"
                  inactive-value="0">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(item,index) in userListData" :key="index"
              :label="item.roleName"
              align="center"
              width="110"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row['roleCheck' + index]"
                             @change="checkData(scope,item)"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="center"
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deleteRoleId(scope)"><span style="color: #F56C6C">删除</span></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="400px">
      <div style="height: 360px;">
        <avue-input-tree
          v-if="dialogVisible"
          ref="deptSelect"
          v-model="deptIds"
          multiple
          checkStrictly
          :props="deptProps"
          :clearable="false"
          placeholder="请选择组织"
          :dic="deptOptions">
        </avue-input-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitDept">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout"
import {add, getUser, update, selectByLevel, roleGetList} from "@/api/system/user";
import {dictionaryBiz} from "@/api/reportTasks";
import {getDept, getDeptTree} from "@/api/system/dept";

export default {
  components: {HeadLayout},
  data() {
    return {
      type: '',
      id: '',
      dataForm: {
        realName: {},
        tenantId: '000000',
        roleIds: [],
        sex: 3,
        isEnabled: 0,
        nationality: '5651',
        personnelType: 1,
        personnelLocation: 1
      },
      rules: {
        code: [
          {required: true, message: '请输入用户编号', trigger: 'blur'},
          {
            pattern: /[a-zA-Z0-9]$/,
            message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
          }
        ],
        account: [
          {required: true, message: '请输入登录账号', trigger: 'blur'},
          {
            pattern: /[a-zA-Z0-9]$/,
            message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
          },
        ],
        phone: [
          {
            required: true,
            pattern: /^1[0-9]{10}$/,
            message: this.$t("cip.plat.sys.user.field.contactNumber"),
            trigger: "blur"
          }
        ],
        "realName.zh": [
          {required: true, message: '请输入用户姓名', trigger: 'blur'}
        ],
        isEnabled: [
          {required: true, message: '请选择是否启用', trigger: 'change'}
        ],
        personnelLocation: [
          {required: true, message: '请选择所在地', trigger: 'change'}
        ],
        nationality: [
          {required: true, message: '请选择国籍', trigger: 'change'}
        ],
        personnelType: [
          {required: true, message: '请选择人员类型', trigger: 'change'}
        ],
      },
      activeName: 'user',
      activeNames: ['1', '2', '3'],
      sexOptions: [],
      isEnabledOptions: [],
      locationOptions: [],
      countryOptions: [],
      personnelTypeOptions: [],
      deptOptions: [],
      deptProps: {
        label: "title",
        value: "value"
      },
      headBtnOptions: [
        {
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: "head-save",
        },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      ],
      deptHeadOptions: [
        {
          label: "配置部门",
          emit: "head-add",
        },
      ],
      dialogVisible: false,
      deptIds: [],
      userListData: [],
      tableData: []
    }
  },
  created() {
    this.getDictory();
    let {id, type} = this.$route.query;
    this.type = type;
    this.id = id;
    if (type == 'edit') {
      this.getUserDetail();
    }
  },
  methods: {
    // 获取数据字典
    getDictory() {
      // 性别
      dictionaryBiz("sex_type").then((res) => {
        this.sexOptions = res.data.data;
      });
      // 是否
      dictionaryBiz("ISENBLE").then((res) => {
        this.isEnabledOptions = res.data.data;
      });
      // 所在地
      dictionaryBiz("personnel_location").then((res) => {
        this.locationOptions = res.data.data;
      });
      // 国籍
      selectByLevel({regionLevel: 1}).then((res) => {
        this.countryOptions = res.data.data;
      })
      // 人员类型
      dictionaryBiz("personnel_type").then((res) => {
        this.personnelTypeOptions = res.data.data;
      });
      // 组织数据
      getDeptTree("000000").then((res) => {
        this.deptOptions = res.data.data;
      });
      // 角色数据
      roleGetList(1, 500).then((res) => {
        this.userListData = res.data.data.records;
      })
    },
    // 只有一个主组织
    chooseDefault(scope) {
      this.dataForm.roleIds.forEach((item, index) => {
        if (scope.$index != index && item.isDefaultDept == '1') {
          item.isDefaultDept = '0'
        }
      })
    },
    // 只有一个默认部门
    chooseDisplay(scope) {
      this.dataForm.roleIds.forEach((item, index) => {
        if (scope.$index != index && item.isDisplay == '1') {
          item.isDisplay = '0'
        }
      })
    },
    // 打开选择组织弹窗
    addDept() {
      let ids = new Array();
      this.dataForm.roleIds.forEach((item) => {
        ids.push(item.deptId)
      })
      this.deptIds = ids;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.deptSelect.$children[0].toggleMenu();
      })
    },
    // 确定选择组织
    submitDept() {
      let dataList = new Array();
      if (this.dataForm.roleIds.length != 0) {
        // 新增
        this.deptIds.forEach((item) => {
          this.dataForm.roleIds.forEach((items) => {
            if (item == items.deptId) {
              dataList.push(items);
            }
          })
        })
        let dataListRoleIds = new Array();
        dataList.forEach((item) => {
          dataListRoleIds.push(item.deptId)
        })
        let filteredArray = [...new Set([...this.deptIds, ...dataListRoleIds])]
          .filter(item => !this.deptIds.includes(item) || !dataListRoleIds.includes(item));
        filteredArray.forEach((item) => {
          getDept(item).then((res) => {
            dataList.push({
              deptName: JSON.parse(res.data.data.deptName).zh,
              deptId: res.data.data.id,
              roleId: [],
              isDefaultDept: 0
            })
          })
        })
      } else {
        // 编辑
        this.deptIds.forEach((item) => {
          getDept(item).then((res) => {
            dataList.push({
              deptName: JSON.parse(res.data.data.deptName).zh,
              deptId: res.data.data.id,
              roleId: [],
              isDefaultDept: 0
            })
          })
        })
      }
      this.dataForm.roleIds = dataList;
      this.dialogVisible = false;
    },
    // 选择角色
    checkData(scope) {
      let indexList = []
      Object.keys(scope.row).forEach(key => {
        if (scope.row[key] === true) {
          indexList.push(key.substring(9))
        }
      });
      scope.row.roleId = [];
      scope.row.roleName = [];
      for (let i = 0; i < this.userListData.length; i++) {
        indexList.forEach(e => {
          if (i.toString() === e) {
            scope.row.roleId.push(this.userListData[i].id)
            scope.row.roleName.push(this.userListData[i].roleName)
          }
        })
      }
    },
    // 删除部门
    deleteRoleId(scope) {
      this.dataForm.roleIds.splice(scope.$index,1)
    },
    // 获取用户信息
    getUserDetail() {
      getUser(this.id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          data.realName = JSON.parse(data.realName)
          // 回显树选择
          data.deptId = data.deptId.split(',');
          // 回显表格勾选角色
          data.roleIds.forEach((e) => {
            if (e.roleId.includes(',')) {
              e.roleId = e.roleId.split(',');
            } else {
              e.roleId = [e.roleId];
            }
            this.userListData.forEach((item, index) => {
              let checked = 'roleCheck' + index;
              this.$set(e, checked, false)
              e.roleId.forEach((roleId) => {
                if (roleId == item.id) {
                  this.$set(e, checked, true)
                }
              })
            })
          })
          this.dataForm = data;
        }
      })
    },
    // 保存
    headSave(type) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let requestType = this.dataForm.hasOwnProperty('id') ? update : add;
          let dataForm = JSON.parse(JSON.stringify(this.dataForm));
          if (dataForm.roleIds.length == 0) {
            this.$message({
              type: "warning",
              message: "请确保用户至少有一个部门"
            });
            return;
          }

          if (dataForm.realName) {
            dataForm.realName = JSON.stringify(dataForm.realName);
          }

          if (dataForm.deptId) {
            dataForm.deptId = dataForm.deptId.join(',');
          }

          // 判断是否勾选主组织
          let isMainDept = dataForm.roleIds.filter((item) => {
            if (item.isDefaultDept == 1) {
              return item;
            }
          })
          if (isMainDept.length == 0) {
            this.$message({
              type: "warning",
              message: "请勾选其中一个部门为主组织"
            });
            return;
          }
          // 判断是否选择了角色
          let isEmptyRole = dataForm.roleIds.filter((item) => {
            if (item.roleId.length == 0) {
              return item;
            }
          })
          if (isEmptyRole.length != 0) {
            this.$message({
              type: "warning",
              message: "请确保每个部门中至少勾选一个角色"
            });
            return;
          }

          dataForm.roleIds.forEach((item) => {
            item.roleId = item.roleId.join(',');
            if (Array.isArray(item.roleName)) {
              item.roleName = item.roleName.join(',');
            }
          })
          requestType(dataForm).then((res) => {
            let data = res.data.data;
            this.id = data.id;
            this.dataForm.id = data.id;
            this.type = 'edit';
            this.getUserDetail();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            if (type == 'back') {
              this.headCancel();
            }
          })
        }
      });
    },
    // 返回
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>
<style lang="scss" scoped>
.mainBox {
  width: 100%;
  height: 100%;
}

.tabsBox {
  background-color: #FFFFFF;
  padding: 10px 12px;
  width: calc(100% - 24px);
  height: calc(100% - 65px);
}

::v-deep .el-tabs__content {
  background-color: transparent;
}
</style>
