var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainBox" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type == "edit"
              ? _vm.$t("cip.plat.sys.user.title.editHeadTitle")
              : _vm.$t("cip.plat.sys.user.title.addHeadTitle"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-cancel": _vm.headCancel,
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "tabsBox" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "用户信息", name: "user" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.dataForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function ($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "基础信息", name: "1" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "用户编号:",
                                            prop: "code",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入用户编号",
                                            },
                                            model: {
                                              value: _vm.dataForm.code,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "code",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.code",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "用户姓名:",
                                            prop: "realName.zh",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入用户姓名",
                                            },
                                            model: {
                                              value: _vm.dataForm.realName.zh,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm.realName,
                                                  "zh",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.realName.zh",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "登录账号:",
                                            prop: "account",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入登录账号",
                                            },
                                            model: {
                                              value: _vm.dataForm.account,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "account",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.account",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "用户性别:",
                                            prop: "sex",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择用户性别",
                                              },
                                              model: {
                                                value: _vm.dataForm.sex,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "sex",
                                                    $$v
                                                  )
                                                },
                                                expression: "dataForm.sex",
                                              },
                                            },
                                            _vm._l(
                                              _vm.sexOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.dictKey,
                                                  attrs: {
                                                    label: item.dictValue,
                                                    value: Number(item.dictKey),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "手机号码:",
                                            prop: "phone",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入手机号码",
                                            },
                                            model: {
                                              value: _vm.dataForm.phone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "phone",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "是否启用:",
                                            prop: "isEnabled",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择是否启用",
                                              },
                                              model: {
                                                value: _vm.dataForm.isEnabled,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "isEnabled",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm.isEnabled",
                                              },
                                            },
                                            _vm._l(
                                              _vm.isEnabledOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.dictKey,
                                                  attrs: {
                                                    label: item.dictValue,
                                                    value: Number(item.dictKey),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "档案信息", name: "2" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "所在地:",
                                            prop: "personnelLocation",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value:
                                                  _vm.dataForm
                                                    .personnelLocation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "personnelLocation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm.personnelLocation",
                                              },
                                            },
                                            _vm._l(
                                              _vm.locationOptions,
                                              function (item, index) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: item.dictKey,
                                                    attrs: {
                                                      label: Number(
                                                        item.dictKey
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.dictValue) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "国籍:",
                                            prop: "nationality",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择国籍",
                                              },
                                              model: {
                                                value: _vm.dataForm.nationality,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "nationality",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm.nationality",
                                              },
                                            },
                                            _vm._l(
                                              _vm.countryOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.code,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "人员类型:",
                                            prop: "personnelType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择人员类型",
                                              },
                                              model: {
                                                value:
                                                  _vm.dataForm.personnelType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "personnelType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm.personnelType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.personnelTypeOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.dictKey,
                                                  attrs: {
                                                    label: item.dictValue,
                                                    value: Number(item.dictKey),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "所属部门", name: "3" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "dept",
                                            "label-width": "0",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dataForm.roleIds,
                                          function (item, index) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: item.id,
                                                staticStyle: {
                                                  "margin-right": "12px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.deptName) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "组织部门", name: "dept" } },
                [
                  _c("head-layout", {
                    attrs: { "head-btn-options": _vm.deptHeadOptions },
                    on: { "head-add": _vm.addDept },
                  }),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.dataForm.roleIds,
                        border: "",
                        stripe: "",
                        size: "medium",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          fixed: "left",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "deptName",
                          label: "组织名称",
                          fixed: "left",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "isDefaultDept",
                          label: "是否主组织",
                          align: "center",
                          fixed: "left",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-text": "是",
                                    "inactive-text": "否",
                                    "active-value": "1",
                                    "inactive-value": "0",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.chooseDefault(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.isDefaultDept,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "isDefaultDept", $$v)
                                    },
                                    expression: "scope.row.isDefaultDept",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "isDisplay",
                          label: "是否默认部门",
                          align: "center",
                          fixed: "left",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-text": "是",
                                    "inactive-text": "否",
                                    "active-value": "1",
                                    "inactive-value": "0",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.chooseDisplay(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row.isDisplay,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "isDisplay", $$v)
                                    },
                                    expression: "scope.row.isDisplay",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._l(_vm.userListData, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.roleName,
                            align: "center",
                            width: "110",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.checkData(scope, item)
                                        },
                                      },
                                      model: {
                                        value: scope.row["roleCheck" + index],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "roleCheck" + index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row['roleCheck' + index]",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          align: "center",
                          label: "操作",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRoleId(scope)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#F56C6C" } },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "360px" } },
            [
              _vm.dialogVisible
                ? _c("avue-input-tree", {
                    ref: "deptSelect",
                    attrs: {
                      multiple: "",
                      checkStrictly: "",
                      props: _vm.deptProps,
                      clearable: false,
                      placeholder: "请选择组织",
                      dic: _vm.deptOptions,
                    },
                    model: {
                      value: _vm.deptIds,
                      callback: function ($$v) {
                        _vm.deptIds = $$v
                      },
                      expression: "deptIds",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitDept } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }